<template>
  <b-container>
  <b-card class="mt-5 mb-5 px-4 py-4">
    <template v-slot:header>
      <h3 class="py-1 text-primary">{{$t('transportation.camelTransport')}}</h3>
    </template>
    <div class="pb-4">
      <p class="font-size-22 text-primary text-justify">
        {{$t('transportation.headerDetails')}}
      </p>
      <div class="mt-5">
        <router-link :to="{name: 'registerTransportation'}" class="w-100 d-flex justify-content-center"><b-button variant="primary" class="py-2 width-15 font-size-20">{{$t('transportation.headerBtn')}}</b-button></router-link>
      </div>
    </div>
  </b-card>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'

export default {
  mounted () {
    core.index()
  },
  data () {
    return {
    }
  }
}
</script>
<style>

</style>
